.App {
    text-align: center;
    min-height: 100vh;
    width: 100%;

    section.container {
        height: 100%;
        min-height: calc(100vh - 54px);
    }

    .footer {
        width: 100%;
        display: flex;
        justify-content: center;

        .footer-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            > div:first-child {
                padding-left: 7px;
            }
        
            div {
                display: flex;
                flex-direction: row;

                > div:first-child {
                   margin-right: 10px; 
                }
            }
        }
    }
}
        
@media screen and (max-width: 500px) {
    .App {
        .footer {
            .footer-container {

                div {
                    flex-direction: column;
                    align-items: flex-start;

                    > div:first-child {
                        margin-right: 0px; 
                    }
                }

                > div:nth-child(2) {
                    align-items: flex-end;
                }
            }
        }
    }
}

.invisible-button {
    background: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}