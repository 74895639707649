.spark-group {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.theme-angel {
    background-image: linear-gradient(180deg,#3385bd,#252626);
    font-family: Montserrat,serif !important;
    color: white;

    a {
        color: white;
        text-decoration: none;
    }


    footer > .footer-container,
    section.container {
        max-width: 900px;
        padding: 15px;
    }
    
    .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    #logo {
        width: 150px;
        height: 70.25px;
    }

    .content {
        margin-top: 10px;

        h1 {
            margin-top: 50px;
            margin-bottom: 20px;
            text-align: left;
            padding-left: 7px;
            font-size: 1.875rem;
        }

        p {
            text-align: left;
            padding-left: 7px;
        }

        h3 {
            margin-top: 50px;
            margin-bottom: 20px;
            text-align: left;
            padding-left: 7px;
        }

        .max-width-button {
            width: 100%;
            max-width: 600px;
        }

        .address-input {
            max-width: 70%;
            width: 100%;
            padding: 5px;

            border-radius: 12px;

            :focus {
                outline: none !important;
            }
        }

        .table-container {
            position: relative;
            min-height: 500px;
            border-radius: 12px;

            .page-container {
                padding: 5px;
                padding-left: 20px;
                display: flex;
                flex-direction: row;
                color: black;
                align-items: center;

                > span {
                    margin-right: 5px;
                }

                .btn:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    .card {
        color: black;

        .card-title {
            text-align: left;
            font-weight: bold;
        }
    }

    .transparent-card {
        background-color: rgba(255,255,255,0.1);
        color: white;
        display: flex;
        flex-direction: column;
        padding: 50px 0px;
        border-radius: 5px;
        width: 49%;
        margin-bottom: 2%;

        :nth-child(1) {
            font-size: 20px;
        }

        :nth-child(2) {
            font-size: 25px;
            font-weight: bold;
        }

        &.big {
            width: 100%;
        }

        &.small {
            width: 32%;
        }
    }

    .btn-success {
        background-color: #6ED482;
    }

    .btn-info {
        background-color: #54a4da;
        border-width: 0px;
        color: white;
    }

    .btn-angel {
        overflow: visible;
        white-space: pre;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 1;

        text-transform: uppercase;
        border-radius: 12px;

        height: 55px;
        -webkit-filter: brightness(1);
        filter: brightness(1);
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        border-color: transparent;
    }

    .btn-search {
        width: 36px;
        height: 36px;
        outline: none !important;
        box-shadow: none !important;
    }
}

/* MEDIAS */
@media screen and (max-width: 500px) {
    .theme-angel {
        footer > .footer-container,
        section.container {
            padding: 10px;
        }

        .transparent-card {
            width: 100% !important;
            margin-bottom: 15px;
    
            :nth-child(1) {
                font-size: 20px;
            }
    
            :nth-child(2) {
                font-size: 25px;
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 300px) {
    .theme-angel {
    
        footer > .footer-container,
        section.container {
            padding: 5px;
        }
    }
}
